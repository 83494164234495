<template>
  <div class="view pa24" >
    <div style="display: flex;flex-wrap: wrap;">
      <div class="mr10">
        <span class="mr10">状态</span>
        <el-select clearable v-model="search.status" class="w220 mb10 mr10" placeholder="请选择状态">
          <el-option v-for="(item,index) in statusList" :key="'info4'+ index" :label="item.name"
                     :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="mr10">
        <span class="mr10">平台订单号</span>
        <el-input clearable class="w220 mr10" v-model="search.transaction_id" placeholder="请输入平台订单号" />
      </div>
      <div class="mr10">
        <span class="mr10">商户订单号</span>
        <el-input clearable class="w220 mr10" v-model="search.out_trade_no" placeholder="请输入商户订单号" />
      </div>
      <div class="mr10">
        <span class="mr10">申请时间</span>
        <el-date-picker :picker-options="pickerOptions" v-model="search.date" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="mr10">
        <span class="mr10">处理时间</span>
        <el-date-picker :picker-options="pickerOptions" v-model="search.date1" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <el-button class="ml10" type="primary" @click="upsearch">查询</el-button>
      <el-button class="ml10" type="primary" @click="chongzhi">重置</el-button>
      <el-button class="mr10 h34" type="danger" @click="daochu">导出</el-button>
    </div>
    <div class="mt20">
      <el-tag effect="plain" class="mr10">成功次数：{{info1.success_count}}</el-tag>
      <el-tag effect="plain" class="mr10" type="success">成功金额：{{info1.success_money}}</el-tag>
      <el-tag effect="plain" class="mr10" type="info">手续费：{{info1.success_sxf}}</el-tag>
      <!-- <el-tag effect="plain" type="warning">成功费用：0</el-tag> -->
    </div>
    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column align="center" label="订单类型"  >
          <template #default="scope">
            <div>{{scope.row.type_text}}提交</div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="创建时间"  />
        <el-table-column prop="transaction_id" align="center" label="平台订单号"  />
        <el-table-column prop="out_trade_no" align="center" label="商户订单号" />
        <el-table-column prop="money" align="center" label="代付金额" />
        <el-table-column prop="sxf" align="center" label="手续费"  />
        <el-table-column prop="total" align="center" label="总金额"  />
        <el-table-column prop="agent_commission" align="center" label="预计佣金"  />
        <el-table-column label="银行信息" width="400">
          <template #default="scope">
            <div class="d-flex flex-column" style="align-items: center;">
              <div>
                开户名：<span style="color: #F56C6C">{{scope.row.name}}</span>
                <img style="width: 15px;margin-left: 5px;cursor: pointer" src="@/assets/images/copy.png" alt="" @click="copy(scope.row.name)">
                <img style="width: 15px;margin-left: 5px;cursor: pointer" src="@/assets/images/copy.png" alt="" @click="copy1(scope.row.name+'\n'+scope.row.bank_name+'\n'+scope.row.subbranch+'\n'+scope.row.cardnumber+'\n'+scope.row.money)">
              </div>
              <div>
                银行名称：<span style="color: #F56C6C">{{scope.row.bank_name}}</span>
                <img style="width: 15px;margin-left: 5px;cursor: pointer" src="@/assets/images/copy.png" alt="" @click="copy(scope.row.bank_name)">
              </div>
              <div>
                支行名称：<span style="color: #F56C6C">{{scope.row.subbranch}}</span>
                <img style="width: 15px;margin-left: 5px;cursor: pointer" src="@/assets/images/copy.png" alt="" @click="copy(scope.row.subbranch)">
              </div>
              <div>
                银行卡号：<span style="color: #F56C6C">{{scope.row.cardnumber}}</span>
                <img style="width: 15px;margin-left: 5px;cursor: pointer" src="@/assets/images/copy.png" alt="" @click="copy(scope.row.cardnumber)">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="verify_time" align="center" label="处理时间"  />
        <el-table-column align="center" label="状态" width="150" >
          <template #default="scope">
            <el-tag v-if="scope.row.status==0">{{scope.row.order_status_text}}</el-tag>
            <el-tag v-if="scope.row.status==1" type="warning">{{scope.row.order_status_text}}</el-tag>
            <el-tag v-if="scope.row.status==2">{{scope.row.order_status_text}}</el-tag>
            <el-tag v-if="scope.row.status==3" type="success">{{scope.row.order_status_text}}</el-tag>
            <el-tag v-if="scope.row.status==4" type="danger">{{scope.row.order_status_text}}</el-tag>
            <el-tag v-if="scope.row.status==5" type="warning">{{scope.row.order_status_text}}</el-tag>

            <el-tag style="margin-left: 10px" type="warning" v-if="scope.row.notify_status == 0">{{scope.row.notify_status_text}}</el-tag>
            <el-tag style="margin-left: 10px" type="success" v-if="scope.row.notify_status == 1">{{scope.row.notify_status_text}}</el-tag>
            <el-tag style="margin-left: 10px" type="danger" v-if="scope.row.notify_status == 2">{{scope.row.notify_status_text}}</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column align="center" label="操作" >-->
<!--          <template slot-scope="scope">-->
<!--            <el-button type="text" style="color: #999;" @click="edit(scope.row, true);">查看</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </template>
    </commonTable>
    <el-dialog title="详情" :visible.sync="centerDialogVisible" :modal-append-to-body="false" width="50%" center>
      <table class="table">
        <tr>
          <td width="40%">下单时间：</td>
          <td>{{info.create_time}}</td>
        </tr>
        <tr>
          <td>平台订单号：</td>
          <td>{{info.transaction_id}}</td>
        </tr>
        <tr>
          <td>商户订单号：</td>
          <td>{{info.out_trade_no}}</td>
        </tr>
        <!-- <tr>
          <td>商户号：</td>
          <td>{{info.mch_id}}</td>
        </tr> -->
        <!--			<tr>-->
        <!--				<td>上级：</td>-->
        <!--				<td>{{info.mch_id}}</td>-->
        <!--			</tr>-->
        <!-- <tr>
          <td>货币：</td>
          <td v-if="info.user">{{info.user.currency_info.name}}</td>
        </tr> -->
        <tr>
          <td>代付金额：</td>
          <td>{{info.money}}</td>
        </tr>
        <tr>
          <td>手续费：</td>
          <td>{{info.sxf}}</td>
        </tr>
        <tr>
          <td>银行名称：</td>
          <td>{{info.bank_name}}</td>
        </tr>
        <tr>
          <td>支行名称：</td>
          <td>{{info.subbranch}}</td>
        </tr>
        <tr>
          <td>银行卡号：</td>
          <td>{{info.cardnumber}}</td>
        </tr>
        <tr>
          <td>开户名：</td>
          <td>{{info.name}}</td>
        </tr>
        <tr>
          <td>审核时间：</td>
          <td>{{info.verify_time}}</td>
        </tr>
        <tr>
          <td>审核操作者：</td>
          <td>{{info.admin_name}}</td>
        </tr>
        <tr>
          <td>审核备注：</td>
          <td>{{info.beizhu}}</td>
        </tr>
        <tr>
          <td>客户端ip：</td>
          <td>{{info.client_ip}}</td>
        </tr>
        <tr>
          <td>代付类型：</td>
          <td>{{info.type_text}}提交</td>
        </tr>
        <tr>
          <td>代付状态：</td>
          <td>{{info.order_status_text}}</td>
        </tr>
        <!-- <tr>
          <td>通道：</td>
          <td v-if="info.dfchannel">{{info.dfchannel.name}}</td>
        </tr>
        <tr>
          <td>三方订单号：</td>
          <td>{{info.three_orderno}}</td>
        </tr> -->
        <tr>
          <td>异步通知地址：</td>
          <td>{{info.notify_url}}</td>
        </tr>
        <tr style="width: 100%">
          <td>异步通知结果：</td>
          <td>
            <div style="width: 500px;overflow: auto" v-html="info.notify_result"></div>
          </td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">关 闭</el-button>
			</span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import Moment from 'moment';
import {
  mapState
} from 'vuex';
import {
  dai_lists,
} from "@/api/settlement";
let ac;
export default {
  name: 'agentRecord',
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '今日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '昨日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, start]);
          }
        },{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      search:{
        status:'',
        transaction_id:'',
        out_trade_no:'',
        date1:'',
        date:''
      },
      statusList: [
        {
          name: '待审核',
          value: 0
        }, {
          name: '未处理',
          value: 1
        }, {
          name: '受理中',
          value: 2
        }, {
          name: '已出款',
          value: 3
        }, {
          name: '已驳回',
          value: 4
        }, {
          name: '已冲正',
          value: 5
        }],
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      centerDialogVisible: false,
      info1:{},
      info:{}
    };
  },
  components: {
    commonTable,
  },
  mounted() {
    this.queryPage();
  },

  methods: {
    async queryPage(payload) {
      let data = {
        page_size: this.pageSize,
        page_no: this.currentPage,
        ...payload
      };
      try {
        this.loading = true;
        const result = await dai_lists(data);
        this.loading = false;
        const {
          count,
          lists,
          extend
        } = result.data;
        this.tableData = lists;
        this.total = count;
        this.info1 = extend
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    upsearch(){
      this.currentPage = 1;
      let data = {
        transaction_id:this.search.transaction_id,
        out_trade_no:this.search.out_trade_no,
        status:this.search.status,
      };
      if(this.search.date) {
        data.start_time = Moment(this.search.date[0]).format('YYYY-MM-DD 00:00:00');
        data.end_time = Moment(this.search.date[1]).format('YYYY-MM-DD 23:59:59');
      }
      if(this.search.date1) {
        data.verify_time1 = Moment(this.search.date1[0]).format('YYYY-MM-DD 00:00:00');
        data.verify_time2 = Moment(this.search.date1[1]).format('YYYY-MM-DD 23:59:59');
      }
      this.queryPage(data);
    },
    copy(val) {
      const textArea = document.createElement('textarea');
      textArea.value = val;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        this.$message.success('复制成功');
      } catch (err) {
        console.error('Could not copy text: ', err);
      }
      document.body.removeChild(textArea);
    },
    copy1(val) {
      const textArea = document.createElement('textarea');
      textArea.value = val;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        this.$message.success('复制成功');
      } catch (err) {
        console.error('Could not copy text: ', err);
      }
      document.body.removeChild(textArea);
    },
    chongzhi(){
      this.currentPage = 1;
      this.search = {
        status:'',
        transaction_id:'',
        out_trade_no:'',
        date1:'',
        date:''
      }
      this.queryPage();
    },
    daochu(){
      let data = {
        transaction_id:this.search.transaction_id,
        out_trade_no:this.search.out_trade_no,
        status:this.search.status,
        export:2
      };
      if(this.search.date) {
        data.start_time = Moment(this.search.date[0]).format('YYYY-MM-DD 00:00:00');
        data.end_time = Moment(this.search.date[1]).format('YYYY-MM-DD 23:59:59');
      }
      if(this.search.date1) {
        data.verify_time1 = Moment(this.search.date1[0]).format('YYYY-MM-DD 00:00:00');
        data.verify_time2 = Moment(this.search.date1[1]).format('YYYY-MM-DD 23:59:59');
      }
      dai_lists(data)
          .then((res) => {
            window.open(res.data.url)
          })
          .catch((err) => {
            console.log(err)
            this.$message.error(err.msg);
          });
    }
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  td, th {
    border: 1px solid #999999;
    padding: 5px;
  }
}
</style>